import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const AchieveQualifications = ({name}) => {

  const AchieveQualificationsTableColumns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Customer ID',
      accessor: 'customer_id',
    },
    {
      header: "Email",
      accessor: 'email',
    },
    {
      header: "Sponsor ID",
      accessor: 'sponsor_id',
    },
    {
      header: "High Rank",
      accessor: 'high_rank',
    },
    {
      header: "Country",
      accessor: 'country',
    },
    {
      header: "Country Description",
      accessor: 'country_description',
    },
    {
      header: "Mail Address",
      accessor: 'mail_address',
    },
    {
      header: "Enrollment Orders",
      accessor: 'enrollment_orders',
    },
    {
      header: "Repeat Orders",
      accessor: 'repeat_orders',
    },
    {
      header: "Rank Advancements",
      accessor: 'rank_advancements',
    },
    {
      header: "Frontline Rank Advancements",
      accessor: 'frontline_rank_advancements',
    },
    {
      header: "Total Points",
      accessor: 'total_points',
    },
    {
      header: "Airfare",
      accessor: 'airfare',
    },
    {
      header: "Hotel",
      accessor: 'hotel',
    },
    {
      header: "Travel Voucher",
      accessor: 'travel_voucher',
    },
  ]

  return (
    <TableReport name={name} title="Achieve 2025 Qualifications" dataColumns={AchieveQualificationsTableColumns} />
  )
}

AchieveQualifications.propTypes = {
  name: PropTypes.string.isRequired,
}

export default AchieveQualifications
