import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const RecentOrders = ({ name }) => {
  const recentOrdersTableColumns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Order Date',
      accessor: 'order_date',
      is_date: true,
    },
    {
      header: 'Order ID',
      accessor: 'order_id',
    },
    {
      header: 'Order Status',
      accessor: 'order_status',
    },
    {
      header: 'Total',
      accessor: 'total',
    },
    {
      header: 'Order Type',
      accessor: 'order_type',
    },
  ]

  return <TableReport name={name} dataColumns={recentOrdersTableColumns} />
}

RecentOrders.propTypes = {
  name: PropTypes.string.isRequired,
}

export default RecentOrders
